<template>
  <div v-if="isLoading">
    <HeaderDesktop class="d-none d-lg-block"></HeaderDesktop>
    <div class="d-block d-lg-none">
      <HeaderTrial class="d-lg-none" v-if="isPlayTrial" />
      <div
        class="headerMobile position-relative"
        :class="{
          mt40: isPlayTrial
        }"
      >
        <UIHeaderMobile :name="titleMobile" :tutorialGame="tutorialGame" />
        <template v-if="!isBuyTogetherDetail">
          <div v-if="isBuyTogether">
            <img
              @click.stop="$store.commit('setIsOpenFilterBuyTogether', true)"
              :src="require('@/assets/img/icon/icon_filter.svg')"
              alt="icon filter"
            />
          </div>
          <div v-else class="ml-auto">
            <NavbarIconsRep />
          </div>
        </template>
      </div>
    </div>
    <!-- <TabGame
      class="tabGameLayout"
      :class="{
        mt90: isPlayTrial,
        customTogetherDetail: isBuyTogetherDetail
      }"
      :isBuyTogether="isBuyTogether"
      :isBuyTogetherDetail="isBuyTogetherDetail"
      :isFeedKeno="isFeedKeno"
    /> -->
    <transition name="fade" mode="out-in">
      <router-view
        v-if="gameId"
        :gameId="gameId"
        :namePage="namePage"
        :bannerByGame="bannerByGame"
        class="layoutSubMain customLayout"
        :keyBannerDesktop="keyBannerDesktop"
        :keyBannerMobile="keyBannerMobile"
        :component="component"
        :key="$route.path"
        :handler="handler"
      />
    </transition>
    <Footer class="d-none d-lg-block"></Footer>
  </div>
</template>
<script>
import HeaderDesktop from '../components/HeaderDesktop';
import Footer from '../components/Footer';
import { getEntityInfo } from '../utils/functions';
import NavbarIconsRep from '../components/HeaderDesktop/NavbarIconsRep';
import UIHeaderMobile from '@/components/UI/Header/UIHeaderMobile';
// import TabGame from '@/components/Game/TabGame';
import HeaderTrial from '@/components/UI/Header/HeaderTrial';

export default {
  name: 'app',
  components: {
    HeaderDesktop,
    UIHeaderMobile,
    Footer,
    NavbarIconsRep,
    // TabGame,
    HeaderTrial
  },
  data() {
    return {
      gameId: undefined,
      isLoading: false,
      keyBannerDesktop: '',
      keyBannerMobile: '',
      namePage: '',
      component: null,
      handler: '',
      actions: {
        // keno: '/luat-choi/luat-choi-keno',
        // power: '/luat-choi/luat-choi-power',
        // mega: '/luat-choi/luat-choi-mega',
        // max3d: '/luat-choi/luat-choi-max3d',
        // 'max3d+': '/luat-choi/luat-choi-max3d+',
        // max3dpro: '/luat-choi/luat-choi-max3dpro',
        // max4d: '/luat-choi/luat-choi-max4d',
        xsmn: '/luat-choi/luat-choi-xsmn'
        // buy_group: 'huong-dan-nhom-mua-chung'
      }
    };
  },
  computed: {
    getListGame() {
      return this.$store.getters.getterGameUrl;
    },
    objBannerGame() {
      const { w_keno } = this.$store.getters.getterBanner;
      let data = { power: w_keno, mega: w_keno };
      return data;
    },
    bannerByGame() {
      let key = Object.keys(this.objBannerGame).filter((p) => p === this.handler);
      return this.objBannerGame[key];
    },
    isKeno() {
      return this.gameId === this.$store.getters.getterGameId['keno'];
    },
    listGameHandler() {
      return this.convertArrayToObject(Object.values(this.$store.getters.getterListGameHandler), 'id');
    },
    tutorialGame() {
      if (!this.gameId || this.isBuyTogetherDetail) return '';
      let currentHandler = this.listGameHandler[this.gameId].handler;
      if (this.isBuyTogether || this.isBuyTogetherDetail) {
        currentHandler = 'buy_group';
      }
      return this.actions[currentHandler];
    },
    isPlayTrial() {
      return this.$store.getters.getterPlayTrial;
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    isBuyTogether() {
      return this.$route.meta.isBuyTogether;
    },
    isBuyTogetherDetail() {
      return this.$route.meta.isBuyTogetherDetail;
    },
    isFeedKeno() {
      return this.$route.meta.isFeedKeno;
    },
    titleMobile() {
      return this.handler === 'xsmn' ? 'VS ' + this.namePage : this.namePage;
    }
  },
  created() {
    this.getGameId();
  },
  mounted() {
    if (this.accountId) {
      this.refreshBalances();
    }
  },
  methods: {
    async getGameId() {
      let entity = await getEntityInfo(this.$route.path, this.getListGame, false);

      if (this.isBuyTogetherDetail) {
        entity.entityName = 'BuyTogetherDetail';
      } else if (this.isFeedKeno) {
        entity.entityName = 'FeedKenoDetail';
      }

      if (entity) {
        this.gameId = entity.entityId;
        this.handler = entity.handler;
        this.namePage = this.isFeedKeno
          ? 'NUÔI KENO'
          : this.isBuyTogether
          ? 'NHÓM MUA CHUNG'
          : this.isBuyTogetherDetail
          ? `NHÓM MUA #${this.$route.params.groupBuyId}`
          : this.getListGame.filter((p) => p.id == this.gameId)[0].shortName || '';
        this.component = this.isBuyTogether ? entity.buyTogetherComponent : entity.entityName;
        this.keyBannerDesktop = entity.keyBannerDesktop;
        this.keyBannerMobile = entity.keyBannerMobile;
      } else {
        return this.$router.push({ name: 'notFound' });
      }

      this.isLoading = true;
    },
    backRouter() {
      this.$router.go(-1);
    }
    // handleOpenFilter() {}
  },
  watch: {
    $route(to, from) {
      if (to != from) {
        this.getGameId();
      }
    },
    accountId: function() {
      this.refreshBalances();
    }
  }
};
</script>
<style lang="scss" scoped>
.customLayout {
  margin-top: 0;
  background-color: #f7f7f7;
}
.tabGameLayout {
  margin-top: 50px;

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    &.customTogetherDetail {
      height: 0px;
      overflow: hidden;
    }
  }
}
</style>
